import type { OnPageTransitionEndAsync } from 'vike/types'

import 'solid-devtools'

export const onPageTransitionEnd: OnPageTransitionEndAsync = async () => {
  console.log('Page transition end')
  document.querySelector('body')?.classList.remove('page-is-transitioning')

  await Promise.resolve()
}
