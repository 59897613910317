import './style.css'

import { Flex } from '../components/ui/Flex'
import type { JSX } from 'solid-js'

export default function LayoutDefault(props: { children?: JSX.Element }) {
  return (
    <Flex fullScreen>
      <Content>{props.children}</Content>
    </Flex>
  )
}

function Content(props: { children: JSX.Element }) {
  return <Flex flex={1}>{props.children}</Flex>
}
